<template>
  <div>
    <div id="StepIndicator" class="stepIndicator" v-if="showServe">
      <el-select
        v-model="value"
        :popper-append-to-body="false"
        class="stepIndicator__select"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
          @click.native="scrollTo(item)"
        >
        </el-option>
      </el-select>
    </div>
    <div style="margin-top: 0px">
      <div class="container">
        <div class="banner">
          <div class="banner-content banner-img">
            <div class="banner-content__center">
              <img
                :src="
                  $isMobile
                    ? gameData.background_small_img
                    : gameData.background_img
                "
                alt=""
              />
            </div>
          </div>
          <img
            :src="gameData.game_icon"
            alt="logo"
            class="banner-logo"
            style="display: none"
          /><span class="banner-title" style="display: none">{{
            gameData.game_name
          }}</span>
        </div>
        <div class="content content_1" ref="info" id="info">
          <div class="content-box">
            <div class="content-box__header">
              <div>
                <span class="content-box__header-number">1</span>
                <span class="content-box__header-content">
                  Tài khoản Game ID
                </span>
              </div>
            </div>
            <div class="content-box__content" v-if="!showReg">
              <div class="loginForm">
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item style="text-align: center">
                    <el-radio-group v-model="ruleForm.type">
                      <el-radio label="1">Email</el-radio>
                      <el-radio label="2">Phone</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="" prop="name">
                    <el-input
                      v-model="ruleForm.name"
                      :placeholder="
                        ruleForm.type === '1'
                          ? 'Tài khoản Email'
                          : 'Tài khoản Phone'
                      "
                    >
                      <template slot="prepend" v-if="ruleForm.type == '2'"
                        >84 -
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="" prop="password">
                    <el-input
                      show-password
                      v-model="ruleForm.password"
                      placeholder="Mật khẩu"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      style="
                        width: 100%;
                        background: rgb(17, 136, 255);
                        border-color: transparent;
                      "
                      @click="submitForm('ruleForm')"
                      >Xác nhận</el-button
                    >
                  </el-form-item>
                  <div class="login_reg" @click="handleReg()">
                    Đăng ký tài khoản
                  </div>
                </el-form>
                <div class="login_other">
                  <div class="loginOther">
                    <div class="dashLeft"></div>
                    <p>Hoặc sử dụng</p>
                    <div class="dashRight"></div>
                  </div>
                  <ul class="list-action">
                    <li>
                      <FbLogin :fb_appid="fb_appid" />
                    </li>
                    <li v-show="client_id">
                      <GoogleLogin :client_id="client_id" ref="GoogleLogin" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="content-box__content" v-if="showReg">
              <el-form
                :model="ruleRegForm"
                :rules="regRules"
                ref="ruleRegForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item prop="email">
                  <el-input
                    name="username"
                    v-model.trim="ruleRegForm.email"
                    oninput="value=value.replace(/[^\x00-\xff]/g, '')"
                    prefix-icon="icon iconfont icon-youjian"
                    placeholder="Hãy nhập email"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <el-input
                    type="text"
                    v-model.trim="ruleRegForm.code"
                    class="codeInput"
                    maxlength="4"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    prefix-icon="icon iconfont icon-baohu1"
                    placeholder="Hãy nhập mã xác thực"
                  >
                    <el-button
                      slot="append"
                      @click="sendMsg"
                      class="sendMsg"
                      type="primary"
                      :disabled="canClick"
                    >
                      {{ content }}
                    </el-button>
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    name="password"
                    v-model.trim="ruleRegForm.password"
                    type="password"
                    maxlength="16"
                    autocomplete="new-password"
                    prefix-icon="icon iconfont icon-mima"
                    oninput="value=value.replace(/[^\x00-\xff]/g, '')"
                    placeholder="Hãy nhập mật khẩu"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item prop="checkPass">
                  <el-input
                    v-model.trim="ruleRegForm.checkPass"
                    type="password"
                    maxlength="16"
                    autocomplete="new-password"
                    prefix-icon="icon iconfont icon-mima"
                    oninput="value=value.replace(/[^\x00-\xff]/g, '')"
                    placeholder="Hãy nhập lại mật khẩu"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item class="button-contaniner">
                  <el-button
                    type="primary"
                    style="
                      width: 100%;
                      background: rgb(17, 136, 255);
                      border-color: transparent;
                    "
                    @click="submitRegForm('ruleRegForm')"
                    >Đăng ký</el-button
                  >
                </el-form-item>
                <div class="login_reg" @click="handleReg('ruleRegForm')">
                  Trở về đăng nhập
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading1 :show="showLoading" :backgroundColor="false" />
  </div>
</template>

<script>
import { debounce } from "lodash";
import Loading1 from "../../components/Loading1";
import FbLogin from "../../components/FbLogin.vue";
import GoogleLogin from "../../components/GoogleLogin.vue";
export default {
  name: "",
  mixins: [],
  components: { FbLogin, GoogleLogin, Loading1 },
  props: {},
  data() {
    let validateEmail = (rule, value, callback) => {
      let reg = new RegExp(
        /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/
      );
      if (value === "") {
        callback(new Error("Hãy nhập email"));
      } else if (!reg.test(value)) {
        callback(new Error("Hãy nhập tài khoản email chính xác!"));
      } else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      let reg = new RegExp(/^([a-zA-Z]|[a-zA-Z0-9_]|[0-9]){6,20}$/);
      if (value === "") {
        callback(new Error("Hãy nhập mật khẩu"));
      } else if (value.length < 6 || !reg.test(value)) {
        callback(
          new Error("Hãy nhập mật khẩu 6-20 ký tự bao gồm chữ cái và số")
        );
      } else {
        if (this.ruleRegForm.checkPass !== "") {
          this.$refs.ruleRegForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Hãy nhập lại mật khẩu"));
      } else if (value !== this.ruleRegForm.password) {
        callback(new Error("Hai lần nhập mật khẩu không giống nhau!"));
      } else {
        callback();
      }
    };
    return {
      fb_appid: "", //fb登录id
      client_id: "", //谷歌登录id
      selectServe: false, //选择区服
      showServe: false, //展示区服
      showReg: false, //是否注册
      showLoading: false,
      readonlyInput: true,
      readonlyCheckInput: true,
      ruleForm: {
        name: "",
        password: "",
        type: "1",
      },
      content: "Gửi tin nhắn",
      totalTime: 60,
      canClick: false,
      ruleRegForm: {
        email: "",
        code: "",
        password: "",
        checkPass: "",
      },
      regRules: {
        email: [
          { required: true, validator: validateEmail, trigger: "change" },
        ],
        code: [
          {
            required: true,
            message: "Hãy nhập mã xác thực",
            trigger: "change",
          },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "change" },
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "change" },
        ],
      },
      rules: {
        name: [
          {
            required: true,
            message: "Vui lòng nhập tài khoản message",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Vui lòng nhập mật khẩu",
            trigger: "blur",
          },
        ],
      },
      gateData: {}, //gate相关数据
      gameData: {}, //游戏点击进来的数据
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.showLoading = true;
    this.isSameGame();
  },
  watch: {
    showReg: function (newValue, oldValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.$refs["ruleRegForm"].resetFields();
          this.ruleRegForm = {
            email: "",
            code: "",
            password: "",
            checkPass: "",
          };
        });
      }
    },
  },
  methods: {
    //判断是否是同一个游戏
    isSameGame() {
      // this.gameData = JSON.parse(localStorage.getItem("game_info"));
      let game_id1 = this.$route.query.g;
      let flag = this.$route.query.flag;
      let game_id2 = JSON.parse(localStorage.getItem("account"))?.game_id;
      let params = {
        g: game_id1,
        flag: flag,
      };
      this.$http.get("/login/index", { params }).then((res) => {
        if (res.data.code === 200) {
          this.gameData = res.data.data;
          this.fb_appid = res.data.data.fb_appid;
          if (res.data.data.client_id) {
            this.client_id = res.data.data.client_id;
            this.$refs.GoogleLogin.clientId = res.data.data.client_id;
            this.$refs.GoogleLogin.init(
              this.$refs.GoogleLogin.handleCredentialResponse
            );
          }
          this.$store.state.gameData = res.data.data;
          if (game_id1 !== game_id2) {
            localStorage.removeItem("token");
            localStorage.removeItem("service");
          }
          this.showLoading = false;
        } else {
          this.$router.push("/"); //返回错误信息跳转到首页
          this.$message.error(res.data.msg);
        }
      });
    },
    //确认校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //判断输入的是不是正确的邮箱或者手机号
          const emailRegex =
            /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/;
          const phoneRegex = /^\d{10}$/;
          if (
            this.ruleForm.type === "1" &&
            !emailRegex.test(this.ruleForm.name)
          ) {
            this.$message.warning("Hãy nhập email chính xác");
            return;
          }
          if (
            this.ruleForm.type === "2" &&
            !phoneRegex.test(this.ruleForm.name)
          ) {
            this.$message.warning("Hãy nhập SĐT chính xác");
            return;
          }
          this.showLoading = false;
          //静态页面先用账号密码来存储，后期上线使用token存储
          let game_id = this.$route.query.g;
          let params = {
            username: this.ruleForm.name,
            password: this.ruleForm.password,
            game_id: game_id,
          };
          if (this.ruleForm.type === "2") {
            params.username = "84-" + params.username;
          }
          this.$http.post("/login/doLogin", params).then((res) => {
            if (res.data.code === 200) {
              let token = res.data.data.token;
              delete params.password;
              localStorage.setItem("token", JSON.stringify(token));
              this.selectServe = true;
              this.$router.push("/consume");
              setTimeout(() => {
                this.showLoading = false;
              }, 2000);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    //注册账号
    handleReg(formName = "ruleRegForm") {
      this.showReg = !this.showReg;
      this.ruleRegForm = {
        email: "",
        code: "",
        password: "",
        checkPass: "",
      };

      if (this.showReg) {
        this.$nextTick(() => {
          this.$refs[formName].resetFields();
        });
      }
    },
    submitRegForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.confirm();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    confirm: debounce(function () {
      let { email, code, password } = this.ruleRegForm;
      let params = {
        g: this.$route.query.g,
        email: email,
        code: code,
        password: password,
      };
      this.$http.get("/reg/normalReg", { params }).then((res) => {
        if (res.data.code === 200) {
          this.showReg = false;
          let token = res.data.data.token;
          localStorage.setItem("token", JSON.stringify(token));
          this.selectServe = true;
          this.$message.success("Liên kết thành công");
          this.$router.push("/consume");
          setTimeout(() => {
            this.showLoading = false;
          }, 2000);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    }, 300),
    sendMsg() {
      if (this.canClick) return;
      if (!this.ruleRegForm.email) {
        this.$message.error("Hãy nhập email");
        return;
      }
      let params = {
        email: this.ruleRegForm.email,
      };
      this.$http.get("/reg/sendCode", { params }).then((res) => {
        if (res.data.code === 200) {
          this.canClick = true;
          this.content = this.totalTime + "s";
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.content = this.totalTime + "s";
            if (this.totalTime < 0) {
              window.clearInterval(clock);
              this.content = "Gửi lại";
              this.totalTime = 60;
              this.canClick = false;
            }
          }, 1000);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    cancelReadOnly() {
      this.readonlyInput = false;
      this.readonlyCheckInput = false;
    },
  },
};
</script>

<style scoped lang="scss">
$color-theme: rgb(17, 136, 255);
$color-theme-rgb: 17, 136, 255;
@import "./css/pc.scss";
@import "./css/phone.scss";
/deep/ .el-form-item__content {
  margin-left: 0 !important;
}
/deep/ .el-popper {
  left: 0px !important;
}
/deep/ .popper__arrow {
  opacity: 0 !important;
}
/deep/ .el-page-header__left {
  color: $color-theme;
}
/deep/ .el-page-header__left::after {
  display: none;
}
</style>
